import React from 'react';
import './Usp.css'; // Ensure you create and import the CSS file for styling

const Usp = () => {
  return (
    <div className="usp">
      <h1>Sell Your House For 1.5%</h1>
      <div className="content-wrapper">
        <h2>1. Transparent:</h2>
        <p>
          I offer my clients the best rates possible, allowing you to keep more of your hard-earned home equity. 
          If you're unhappy for any reason, you can cancel your contract at no cost.
        </p>
        <h2>2. Simple:</h2>
        <p>
          I strive to make the home-selling process as simple and stress-free as possible. I take care of all 
          marketing and listing details while maintaining clear communication with my clients.
        </p>
        <h2>3. Professional:</h2>
        <p>
          I work with professional photographers and videographers to make your listing stand out online. I ensure your 
          property is prominently featured on Zillow, Redfin, and other syndicated sites, reaching the widest 
          audience possible.
        </p>
        <div className="investment-highlight">
          <h3>Maximize Your Return On Investment.</h3>
        </div>
      </div>
    </div>
  );
};

export default Usp;