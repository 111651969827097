import React from 'react';
import Header from './Header';
import './Home.css'; // Uncomment this line to use the CSS
import Usp from './Usp';
import Testimonial from './Testimonial';
import RecentlySold from './RecentlySold';

const Home = () => {



  return (
    <div>
      <Header /> 

      <div className="home-content">{/* Wrapper for the main content of the home page */}
          <div class="hero-section">
            <div class="header-container">        
              <div class="header-text">
                  <h1>Nate Loker</h1>
                  <h2>Columbia Gorge Realtor | Real Broker LLC </h2>
              </div>
              <img src="head_shot.jpg" alt="Nate Loker" className="headshot" />
              </div>
              <div className="consultation-box">
                <a href="https://calendly.com/consultation-with-nate" target="_blank" rel="noopener noreferrer" className="consultation-link">
              Free Consultation
              </a>

              </div>
            </div>
        </div>
        <Usp />
        <Testimonial />
        <RecentlySold />
    </div>
  );
}

export default Home;
  